import { FilterMetadata, SelectItem, SortMeta } from 'primeng/api';
import { camelize, getPropertyValue, stringFormat } from './string-operations';
import { TooltipEvent, TooltipPosition } from './tooltip-model';

export enum RowSelectionType {
  Checkbox = 'checkbox',
  RadioButton = 'radiobutton',
  Row = 'row'
}

export interface RowSelectEvent<TData extends IGridRow> {
  data: TData;
  type: RowSelectionType;
  index: number;
}

export interface ContextMenuSelectEvent<TData extends IGridRow> {
  data: TData;
}

export interface IGridRow {
  rowId: string;
}

export class GridFilterSelectItem implements SelectItem {
  label: string;
  value: any;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;

  constructor(init?: Partial<GridFilterSelectItem>) {
    Object.assign(this, init);
  }
}

export type GetValue = (row: IGridRow) => any;
export type FormatValue = (value: any) => string;
export type ConvertValue = (value: any) => any;

export class GridFilter {
  field: string;
  loading: boolean;
  /**
   * Available values for matchMode are:
   * 'startsWith', 'contains', 'endsWith',
   * 'equals', 'notEquals', 'in',
   * 'lt', 'lte', 'gt' 'gte',
   * 'is', 'isNot', 'before', 'after',
   * 'arrayInAll', 'arrayInAny', 'date'
   */
  matchMode: string;
  formatValue: FormatValue;
  convertValue: ConvertValue;
  selectedValues: Array<any>;
  availableValues: Array<GridFilterSelectItem>;
  initialValues: Array<any>;
  filterMethod?: Function;
  delay: number;

  constructor(init?: Partial<GridFilter>) {
    Object.assign(this, init);
    this.loading = true;
    this.matchMode = this.matchMode || 'in';
    this.convertValue = this.convertValue || GridFilter.defaultConvertValue;
    this.availableValues = this.availableValues || new Array<GridFilterSelectItem>();
  }

  private static defaultConvertValue(value: any): any {
    return value;
  }
}

export class GridColumn {
  align: string;
  field: string;
  header: string;
  hidden: boolean;
  filter: GridFilter;
  fieldId: string;
  getValue: GetValue;
  formatValue: FormatValue;
  sortable: boolean = true;
  getValueTooltip: FormatValue;
  getValueTooltipEvent: TooltipEvent;
  getValueTooltipPosition: TooltipPosition;
  getValueTooltipStyleClass: string;

  constructor(init?: Partial<GridColumn>) {
    Object.assign(this, init);
    this.fieldId = camelize(this.field);
    this.getValue = this.getValue || this.defaultGetValue;
    this.formatValue = this.formatValue || stringFormat;
    this.getValueTooltipEvent = this.getValueTooltipEvent || TooltipEvent.hover;
    this.getValueTooltipPosition = this.getValueTooltipPosition || TooltipPosition.top;
  }

  private defaultGetValue(row: IGridRow): any {
    return getPropertyValue(row, this.field);
  }
}

export class ShowFilterEvent {
  col: GridColumn;
  filters?: {
    [s: string]: FilterMetadata;
  };
  globalFilter?: any;

  constructor(init?: Partial<ShowFilterEvent>) {
    Object.assign(this, init);
  }
}

export class SortRequest implements SortMeta {
  field: string;
  order: number;

  constructor(init?: Partial<SortRequest>) {
    Object.assign(this, init);
  }
}

export class FieldFilterRequest implements FilterMetadata {
  label: string;
  field: string;
  value?: any;
  matchMode?: string;

  constructor(init?: Partial<FieldFilterRequest>) {
    Object.assign(this, init);
  }
}

export class GlobalFilterRequest implements FilterMetadata {
  fields: Array<string>;
  value?: any;
  matchMode?: string;

  constructor(init?: Partial<GlobalFilterRequest>) {
    Object.assign(this, init);
  }
}

export abstract class FilterRequestBase {
  filterFields?: Array<string>;
  filters?: Array<FieldFilterRequest>;
  globalFilter?: GlobalFilterRequest;

  protected constructor(init?: Partial<FilterRequestBase>) {
    Object.assign(this, init);
  }
}

export class FilterSortPageRequest<TRequest> extends FilterRequestBase {
  data?: TRequest;
  first?: number;
  rows?: number;
  sorts?: Array<SortRequest>;

  constructor(init?: Partial<FilterSortPageRequest<TRequest>>) {
    super(init);
    Object.assign(this, init);
  }
}

export class FilterSortPageResponse<TData extends IGridRow> {
  filters: Array<FilterValuesResponse>;
  results: Array<TData>;
  totalCount: number;

  constructor(init?: Partial<FilterSortPageResponse<TData>>) {
    Object.assign(this, init);
  }
}

export class FilterValuesRequest<TRequest> extends FilterRequestBase {
  data?: TRequest;
  field?: string;

  constructor(init?: Partial<FilterValuesRequest<TRequest>>) {
    super(init);
    Object.assign(this, init);
  }
}

export class FilterValuesResponse {
  field: string;
  values: Array<any>;

  constructor(init?: Partial<FilterValuesResponse>) {
    Object.assign(this, init);
  }
}

export class FilterDataEvent {
  filters: { [field: string]: FilterMetadata | Array<FilterMetadata> };
  filteredData: Array<IGridRow>;

  constructor(init?: Partial<FilterDataEvent>) {
    Object.assign(this, init);
  }
}

export class SortDataEvent implements SortMeta {
  field: string;
  order: number;
  multisortmeta: Array<SortMeta>;
}
