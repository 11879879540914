export enum TooltipPosition {
  right = 'right',
  left = 'left',
  top = 'top',
  bottom = 'bottom'
}

export enum TooltipEvent {
  hover = 'hover',
  focus = 'focus'
}
