import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { HttpErrorHandler } from "./http-error-handler";
import { Observable } from "rxjs";
import { NgResponse } from "../shared/ng-response";
import { NgRequest } from "../shared/ng-request";
import { FilterSortPageRequest, FilterValuesRequest, FilterValuesResponse } from "../shared/grid.model";
import {
  DeleteRevokedSubmissionsRequest,
  MissedSubmissionsRequest,
  PublishingSubmissionChartData,
  RegenerateSubmissionsRequest,
} from '../tools/pre-publish-operations/pre-publish-operations.model';
import { ReportingPeriods } from "../shared/reporting-periods";
import { EmptyResponse} from "../shared/empty-response";
import { ViewRollupArtifactsResponse } from "../shared/rollup-artifact.model";
import { ViewSubmissionArtifactsResponse } from "../shared/submission-artifact.model";
import { ArtifactBlobRequest, DeleteArtifactsRequest, Request } from "../shared/artifacts.model";
import { PublishingSubmissionsRequest, ViewPublishingSubmissionsResponse } from "../shared/publishing-submission.model";
import { PublishingRollupsRequest, ViewPublishingRollupsResponse } from "../shared/publishing-rollup.model";
import { PublishingJobsRequest, ViewPublishingJobsResponse } from "../shared/publishing-job.model";
import { LogApiResponse } from "../shared/publishing-log.model";
import { ViewEntityItemsResponse } from "../tools/pre-publish-results/pre-publish-results.model";
import { JobsNotFinishedResponse } from '../shared/job-not-finished.model';

@Injectable()
export class DataService extends HttpClientService {
  constructor(readonly httpClient: HttpClient,
              readonly httpErrorHandler: HttpErrorHandler) {
    super('DataService', httpClient, httpErrorHandler);
  }

  fetchRollupArtifactItems(request: NgRequest<FilterSortPageRequest<Request>>): Observable<NgResponse< ViewRollupArtifactsResponse>> {
    return this.postBody<FilterSortPageRequest<Request>,  ViewRollupArtifactsResponse>('fetchItems', `api/RollupArtifacts/Items`, request);
  }

  fetchRollupArtifactFilterValues(request: NgRequest<FilterValuesRequest<Request>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<Request>, FilterValuesResponse>('fetchFilterValues', 'api/RollupArtifacts/FilterValues', request);
  }

  fetchSubmissionArtifactItems(request: NgRequest<FilterSortPageRequest<Request>>): Observable<NgResponse<ViewSubmissionArtifactsResponse>> {
    return this.postBody<FilterSortPageRequest<Request>, ViewSubmissionArtifactsResponse>('fetchItems', `api/SubmissionArtifacts/Items`, request);
  }

  fetchSubmissionArtifactFilterValues(request: NgRequest<FilterValuesRequest<Request>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<Request>, FilterValuesResponse>('fetchFilterValues', 'api/SubmissionArtifacts/FilterValues', request);
  }

  fetchPublishingSubmissionItems(request: NgRequest<FilterSortPageRequest<PublishingSubmissionsRequest>>): Observable<NgResponse<ViewPublishingSubmissionsResponse>> {
    return this.postBody<FilterSortPageRequest<PublishingSubmissionsRequest>, ViewPublishingSubmissionsResponse>('fetchItems', 'api/PublishingSubmissions/Items', request);
  }

  fetchPublishingSubmissionsFilterValues(request: NgRequest<FilterValuesRequest<PublishingSubmissionsRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<PublishingSubmissionsRequest>, FilterValuesResponse>('fetchFilterValues', 'api/PublishingSubmissions/FilterValues', request);
  }

  fetchPublishingRollupItems(request: NgRequest<FilterSortPageRequest<PublishingRollupsRequest>>): Observable<NgResponse<ViewPublishingRollupsResponse>> {
    return this.postBody<FilterSortPageRequest<PublishingRollupsRequest>, ViewPublishingRollupsResponse>('fetchItems', 'api/PublishingRollups/Items', request);
  }

  fetchPublishingRollupFilterValues(request: NgRequest<FilterValuesRequest<PublishingRollupsRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<PublishingRollupsRequest>, FilterValuesResponse>('fetchFilterValues', 'api/PublishingRollups/FilterValues', request);
  }

  reRunPublishingRollup(id: string): Observable<NgResponse<EmptyResponse>> {
    return this.put<EmptyResponse>('reRunPublishingRollup', `api/PublishingRollup/${id}`);
  }

  getSubmissionArtifactBlob(request: NgRequest<ArtifactBlobRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<ArtifactBlobRequest>('getSubmissionArtifactBlob', 'api/SubmissionArtifact/Blob', request);
  }

  getRollupArtifactBlob(request: NgRequest<ArtifactBlobRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<ArtifactBlobRequest>('getRollupArtifactBlob', 'api/RollupArtifact/Blob', request);
  }

  getPublishingSubmissionChartData(fullFiscalYear: string, reportingPeriod: ReportingPeriods): Observable<NgResponse<PublishingSubmissionChartData>> {
    return this.get<PublishingSubmissionChartData>('getPublishingSubmissionChartData', `api/Data/PublishingSubmissionChartData/${fullFiscalYear}/${reportingPeriod}`);
  }

  getPublishingSubmissionTotalExpected(fullFiscalYear: string, reportingPeriod: ReportingPeriods): Observable<NgResponse<number>> {
    return this.get<number>('getPublishingSubmissionTotalExpected', `api/Data/PublishingSubmissionTotalExpected/${fullFiscalYear}/${reportingPeriod}`);
  }

  getPublishingRollupLog(id: string): Observable<NgResponse<LogApiResponse>> {
    return this.get<LogApiResponse>('getPublishingRollupLog', `api/PublishingRollup/Log/${id}`);
  }

  getPublishingSubmissionLog(id: string): Observable<NgResponse<LogApiResponse>> {
    return this.get<LogApiResponse>('getPublishingSubmissionLog', `api/PublishingSubmission/Log/${id}`);
  }

  regenerateSubmissionArtifacts(request: NgRequest<RegenerateSubmissionsRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<RegenerateSubmissionsRequest, EmptyResponse>('regenerateSubmissionArtifacts', 'api/Data/RegenerateSubmissionArtifacts', request);
  }

  generateRollupArtifacts(reportingPeriod: ReportingPeriods): Observable<NgResponse<EmptyResponse>> {
    return this.put<EmptyResponse>('resetRollupArtifacts', `api/PublishingRollup/Reset/${reportingPeriod}`);
  }

  publishArtifacts(reportingPeriod: ReportingPeriods): Observable<NgResponse<EmptyResponse>> {
    return this.put<EmptyResponse>('publishArtifacts', `api/PublishingJob/Insert/${reportingPeriod}`);
  }

  generatePublishingMissedSubmissions(request: NgRequest<MissedSubmissionsRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<MissedSubmissionsRequest, EmptyResponse>('generatePublishingMissedSubmissions', 'api/Data/AddPublishingMissedSubmissions', request);
  }

  deleteRevokedPublishingSubmissionJobs(request: NgRequest<DeleteRevokedSubmissionsRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<DeleteRevokedSubmissionsRequest, EmptyResponse>('deleteRevokedPublishingSubmissionJobs', `api/PublishingSubmissions/DeleteRevoked`, request);
  }

  deleteAllRevokedPublishingSubmissionJobs(reportingPeriod: ReportingPeriods): Observable<NgResponse<EmptyResponse>> {
    return this.delete('deleteAllRevokedPublishingSubmissionJobs', `api/PublishingSubmissions/DeleteAllRevoked/${reportingPeriod}`);
  }

  fetchPublishingJobItems(request: NgRequest<FilterSortPageRequest<PublishingJobsRequest>>): Observable<NgResponse<ViewPublishingJobsResponse>> {
    return this.postBody<FilterSortPageRequest<PublishingJobsRequest>, ViewPublishingJobsResponse>('fetchItems', 'api/PublishingJobs/Items', request);
  }

  fetchPublishingJobFilterValues(request: NgRequest<FilterValuesRequest<PublishingJobsRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<PublishingJobsRequest>, FilterValuesResponse>('fetchFilterValues', 'api/PublishingJobs/FilterValues', request);
  }

  reRunPublishingJob(id: string): Observable<NgResponse<EmptyResponse>> {
    return this.put<EmptyResponse>('reRunPublishingJob', `api/PublishingJob/${id}`);
  }

  getPublishingJobLog(id: string): Observable<NgResponse<LogApiResponse>> {
    return this.get<LogApiResponse>('getPublishingJobLog', `api/PublishingJob/Log/${id}`);
  }

  fetchEntitiesWithArtifactsItems(request: NgRequest<FilterSortPageRequest<Request>>): Observable<NgResponse< ViewEntityItemsResponse>> {
    return this.postBody<FilterSortPageRequest<Request>,  ViewEntityItemsResponse>('fetchItems', `api/EntitiesWithArtifacts/Items`, request);
  }

  fetchEntitiesWithArtifactsFilterValues(request: NgRequest<FilterValuesRequest<Request>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<Request>, FilterValuesResponse>('fetchFilterValues', 'api/EntitiesWithArtifacts/FilterValues', request);
  }

  deleteRollupArtifact(artifactId: string): Observable<NgResponse<EmptyResponse>> {
    return this.delete<EmptyResponse>('deleteOne', `api/RollupArtifacts/DeleteOne/${artifactId}`);
  }

  deleteRollupArtifacts(request: NgRequest<DeleteArtifactsRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<DeleteArtifactsRequest, EmptyResponse>('deleteMany', 'api/RollupArtifacts/DeleteMany', request);
  }

  deleteAllRollupArtifacts(fullFiscalYear: string, reportingPeriod: ReportingPeriods, cdsCode: string): Observable<NgResponse<EmptyResponse>> {
    return this.delete<EmptyResponse>('deleteAll', `api/RollupArtifacts/DeleteAll/${fullFiscalYear}/${reportingPeriod}/${cdsCode}`);
  }

  getJobsNotFinishedAsync(): Observable<NgResponse<JobsNotFinishedResponse>> {
    return this.get<JobsNotFinishedResponse>('getJobsNotFinished', 'api/Data/JobsNotFinished');
  }
}
