export enum ArtifactTypes {
  Import = 'Import',
  Data = 'Data',
  Form = 'Form',
  All = 'All'
}

export class ArtifactTypeLabels {
  [artifactTypes: string]: string;

  constructor() {
    this[ArtifactTypes.Import] = 'Single Export (Dat File)';
    this[ArtifactTypes.Data] = 'Data Extract (Excel File)';
    this[ArtifactTypes.Form] = 'Form (PDF)';
    this[ArtifactTypes.All] = 'All (Zip File)';
  }
}
