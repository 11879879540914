import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';

import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';
import {
  CreateAccountRequest, CreateAccountResponse,
  CreatePasswordRequest, CreatePasswordResponse,
  LoginRequest, LoginResponse, LogoutResponse,
  RequestResetPasswordRequest, RequestResetPasswordResponse,
  ResetPasswordResponse, ResetPasswordRequest, KeepSessionAliveResponse
} from '../shared/security.model';

import { UpdateUserDefaultEntityNgResponse, UpdateUserDefaultFiscalYearNgResponse } from '../shared/user.model';
import { ChangePasswordRequest, ChangePasswordResponse } from '../security/change-password/change-password.model';
import { CommonAdminDistrictResponse } from '../tools/common-admin-disctrict-list/common-admin-district-model';
import {
  CountiesResponse, EntitiesListResponse, EntitiesListRequest, LEAApiRequest,
  CountyApiRequest, CharterApiRequest, FilteredCountyApiResponse, FilteredLEAApiResponse, FilteredCharterApiResponse
} from '../tools/entity-lists/entity-lists.model';
import { LapsedDistrictsResponse } from '../shared/lapsed-districts.model';
import { SELPAsResponse, SELPAResponse, EditSELPAResponse, EditSELPARequest, EditSELPAMemberRequest, AddSELPAMemberRequest, SELPAReviewApiResponse } from '../shared/selpa.model';
import { Entity } from "../shared/entity.model";
import { CharterSchoolManagement, CharterSchoolManagementApiResponse } from '../shared/charter-schools-form.model';
import { CommonAdminEntityRelationshipRequest, CommonAdminEntityRelationshipResponse } from '../tools/common-admin-entity-relationship/common-admin-entity-relationship.model';
import { FilterSortPageRequest, FilterValuesRequest, FilterValuesResponse, FilterSortPageResponse } from '../shared/grid.model';
import { AppContext } from '../app.context';
import { EmptyResponse } from '../shared/empty-response';
import { ReplaceItemRequest } from '../shared/replace-item-request';
import { EntityTypes } from "../shared/entity-types";
import { LapsedDistrictsRequest, LapsedDistrictsNgResponse } from '../tools/lapsed-districts/lapsed-districts.model';
import { EntityTypesResponse } from '../shared/entity-type.model';
import { EntitySubTypeDefinitionResponse } from "../tools/entity-management/entity-management.model";
import { DatasetTypes } from '../shared/dataset-types';


@Injectable()
export class SecurityService extends HttpClientService {
  constructor(
    readonly appContext: AppContext,
    readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('SecurityService', httpClient, httpErrorHandler);
  }

  createAccount(createAccountRequest: NgRequest<CreateAccountRequest>): Observable<NgResponse<CreateAccountResponse>> {
    return this.postBody<CreateAccountRequest, CreateAccountResponse>('createAccount', 'api/Security/CreateAccount', createAccountRequest);
  }

  createPassword(createPasswordRequest: NgRequest<CreatePasswordRequest>): Observable<NgResponse<CreatePasswordResponse>> {
    return this.putBody<CreatePasswordRequest, CreatePasswordResponse>('createPassword', 'api/Security/CreatePassword', createPasswordRequest);
  }

  forgotPassword(forgotPasswordRequest: NgRequest<RequestResetPasswordRequest>): Observable<NgResponse<RequestResetPasswordResponse>> {
    return this.postBody<RequestResetPasswordRequest, RequestResetPasswordResponse>('login', 'api/Security/ForgotPassword', forgotPasswordRequest);
  }

  login(loginRequest: NgRequest<LoginRequest>): Observable<NgResponse<LoginResponse>> {
    return this.postBody<LoginRequest, LoginResponse>('login', 'api/Security/Login', loginRequest);
  }

  logOut(): Observable<NgResponse<LogoutResponse>> {
    const result = this.get<LogoutResponse>('logout', 'api/Security/Logout');
    result.subscribe(ngResponse => this.appContext.loggedInUser = null);
    return result;
  }

  requestResetPassword(requestResetPasswordRequest: NgRequest<RequestResetPasswordRequest>): Observable<NgResponse<RequestResetPasswordResponse>> {
    return this.putBody<RequestResetPasswordRequest, RequestResetPasswordResponse>('resetPassword', 'api/Security/RequestResetPassword', requestResetPasswordRequest);
  }

  requestResetPasswordbyAdmin(requestResetPasswordRequest: NgRequest<RequestResetPasswordRequest>): Observable<NgResponse<RequestResetPasswordResponse>> {
    return this.putBody<RequestResetPasswordRequest, RequestResetPasswordResponse>('resetPassword', 'api/Security/RequestResetPasswordByAdmin', requestResetPasswordRequest);
  }

  resetPassword(resetPasswordRequest: NgRequest<ResetPasswordRequest>): Observable<NgResponse<ResetPasswordResponse>> {
    return this.putBody<ResetPasswordRequest, ResetPasswordResponse>('resetPassword', 'api/Security/ResetPassword', resetPasswordRequest);
  }

  changePassword(changePasswordRequest: NgRequest<ChangePasswordRequest>): Observable<NgResponse<ChangePasswordResponse>> {
    return this.putBody<ChangePasswordRequest, ChangePasswordResponse>('changePassword', 'api/Security/ChangePassword', changePasswordRequest);
  }

  updateDefaultEntity(cdsCode: string): Observable<NgResponse<UpdateUserDefaultEntityNgResponse>> {
    return this.put<UpdateUserDefaultEntityNgResponse>('updateUserDefaultEntity', `api/Security/User/Update/DefaultEntity/${cdsCode}`);
  }

  updateDefaultFiscalYear(year: string): Observable<NgResponse<UpdateUserDefaultFiscalYearNgResponse>> {
    return this.put<UpdateUserDefaultFiscalYearNgResponse>('updateUserDefaultFiscalYear', `api/Security/User/Update/DefaultFiscalYear/${year}`);
  }

  getCommonAdminDistricts(): Observable<NgResponse<CommonAdminDistrictResponse>> {
    return this.get<CommonAdminDistrictResponse>('getCommonAdminDistricts', `api/Security/CommonAdminDistricts`);
  }

  fetchCommonAdminEntityRelationshipItems(request: NgRequest<FilterSortPageRequest<CommonAdminEntityRelationshipRequest>>): Observable<NgResponse<CommonAdminEntityRelationshipResponse>> {
    return this.postBody<FilterSortPageRequest<CommonAdminEntityRelationshipRequest>, CommonAdminEntityRelationshipResponse>('fetchCommonAdminEntityRelationshipItems', `api/CommonAdminEntityRelationships/Items`, request);
  }

  fetchCommonAdminEntityRelationshipFilterValues(request: NgRequest<FilterValuesRequest<CommonAdminEntityRelationshipRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<CommonAdminEntityRelationshipRequest>, FilterValuesResponse>('fetchCommonAdminEntityRelationshipFilterValues', 'api/CommonAdminEntityRelationships/FilterValues', request);
  }

  addCommonAdminEntityRelationship(commonAdminEntityRelationshipRequest: NgRequest<CommonAdminEntityRelationshipRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<CommonAdminEntityRelationshipRequest, EmptyResponse>('addCommonAdminEntityRelationship', 'api/CommonAdminEntityRelationships', commonAdminEntityRelationshipRequest);
  }

  replaceCommonAdminEntityRelationship(commonAdminEntityRelationshipReplaceRequest: NgRequest<ReplaceItemRequest<CommonAdminEntityRelationshipRequest>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<CommonAdminEntityRelationshipRequest>, EmptyResponse>('replaceCommonAdminEntityRelationship', 'api/CommonAdminEntityRelationships/Replace', commonAdminEntityRelationshipReplaceRequest);
  }

  deleteCommonAdminEntityRelationship(commonAdminEntityRelationshipDeleteRequest: NgRequest<CommonAdminEntityRelationshipRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<CommonAdminEntityRelationshipRequest, EmptyResponse>('deleteCommonAdminEntityRelationship', 'api/CommonAdminEntityRelationships/Delete', commonAdminEntityRelationshipDeleteRequest);
  }

  getEntityTypes(): Observable<NgResponse<EntityTypesResponse>> {
    return this.get<EntityTypesResponse>('getEntityTypes', 'api/Security/EntityTypes');
  }

  getEntitiesByType(entitiesRequest: NgRequest<EntitiesListRequest>): Observable<NgResponse<EntitiesListResponse>> {
    return this.postBody<EntitiesListRequest, EntitiesListResponse>('getEntitiesByType', 'api/Security/EntitiesByType', entitiesRequest);
  }

  getAllByType(entitiesRequest: NgRequest<EntitiesListRequest>): Observable<NgResponse<EntitiesListResponse>> {
    return this.postBody<EntitiesListRequest, EntitiesListResponse>('getEntitiesByType', 'api/Security/AllByType', entitiesRequest);
  }

  getEntitiesByTypeLight(entitiesRequest: NgRequest<EntitiesListRequest>): Observable<NgResponse<EntitiesListResponse>> {
    return this.postBody<EntitiesListRequest, EntitiesListResponse>('getEntitiesByTypeLight', 'api/Security/EntitiesByTypeLight', entitiesRequest);
  }

  getCounties(): Observable<NgResponse<CountiesResponse>> {
    return this.get<CountiesResponse>('getCounties', 'api/Security/Counties');
  }

  getLapsedDistricts(): Observable<NgResponse<LapsedDistrictsResponse>> {
    return this.get<LapsedDistrictsResponse>('getLapsedDistricts', 'api/Security/LapsedDistricts');
  }

  getEntitiesPromotedToCDE(datasetType?: DatasetTypes): Observable<NgResponse<EntitiesListResponse>> {
    return datasetType
      ? this.post<EntitiesListResponse>('getEntitiesPromotedToCDE', `api/Security/EntitiesPromotedToCDE/${datasetType}`)
      : this.post<EntitiesListResponse>('getEntitiesPromotedToCDE', 'api/Security/EntitiesPromotedToCDE');
  }

  getSELPAs(): Observable<NgResponse<SELPAsResponse>> {
    return this.get<SELPAsResponse>('getSELPAs', 'api/Security/SELPAs');
  }

  getSELPAMembers(selpaId: string): Observable<NgResponse<SELPAResponse>> {
    return this.get<SELPAResponse>('getSELPAMembers', `api/Security/SELPAs/${selpaId}/members`);
  }

  getSELPAAUReviewList(selpaCode: string, caFiscalYear: string, reportingPeriod: string): Observable<NgResponse<SELPAReviewApiResponse>> {
    return this.get<SELPAReviewApiResponse>('getSELPAMembers', `api/Security/SELPAs/${selpaCode}/${caFiscalYear}/${reportingPeriod}/reviewlist`);
  }

  editSELPA(editSELPARequest: NgRequest<EditSELPARequest>): Observable<NgResponse<EditSELPAResponse>> {
    return this.putBody<EditSELPARequest, EditSELPAResponse>('editSELPA', `api/Security/SELPAs`, editSELPARequest);
  }

  editSELPAMember(request: NgRequest<EditSELPAMemberRequest>): Observable<NgResponse<EditSELPAResponse>> {
    return this.putBody<EditSELPAMemberRequest, EditSELPAResponse>('editSELPAMember', `api/Security/SELPAs/Members`, request);
  }

  addSELPAMember(request: NgRequest<AddSELPAMemberRequest>): Observable<NgResponse<EditSELPAResponse>> {
    return this.postBody<AddSELPAMemberRequest, EditSELPAResponse>('addSELPAMember', `api/Security/SELPAs/Members`, request);
  }

  deleteSELPAMember(selpaId: string): Observable<NgResponse<EditSELPAResponse>> {
    return this.delete<EditSELPAResponse>('deleteSELPAMember', `api/Security/Members/${selpaId}`);
  }

  updateLEAEntity(request: NgRequest<Entity>): Observable<NgResponse<Entity>> {
    return this.putBody<Entity, Entity>('updateLEAEntity', `api/Security/UpdateLEAEntity`, request);
  }
  addLEAEntity(request: NgRequest<Entity>): Observable<NgResponse<Entity>> {
    return this.putBody<Entity, Entity>('addLEAEntity', `api/Security/AddLEAEntity`, request);
  }
  deleteLEAEntity(request: NgRequest<Entity>): Observable<NgResponse<Entity>> {
    return this.putBody<Entity, Entity>('deleteLEAEntity', `api/Security/DeleteLEAEntity`, request);
  }
  updateCharterSchool(request: NgRequest<CharterSchoolManagement>): Observable<NgResponse<CharterSchoolManagement>> {
    return this.putBody<CharterSchoolManagement, CharterSchoolManagement>('updateCharterchool', `api/Security/UpdateCharterSchool`, request);
  }
  addCharterSchool(request: NgRequest<CharterSchoolManagement>): Observable<NgResponse<CharterSchoolManagement>> {
    return this.putBody<CharterSchoolManagement, CharterSchoolManagement>('addCharterchool', `api/Security/AddCharterSchool`, request);
  }
  deleteCharterSchool(request: NgRequest<CharterSchoolManagement>): Observable<NgResponse<CharterSchoolManagement>> {
    return this.putBody<CharterSchoolManagement, CharterSchoolManagement>('deleteCharterchool', `api/Security/DeleteCharterSchool`, request);
  }

  getCharterSchools(): Observable<NgResponse<CharterSchoolManagementApiResponse>> {
    return this.get<CharterSchoolManagementApiResponse>('getCharterSchools', 'api/Security/GetCharterSchools');
  }

  getSELPAByCode(selpaCode: string): Observable<NgResponse<SELPAResponse>> {
    return this.get<SELPAResponse>('getSELPAByCode', `api/Security/SELPAs/ByCode/${selpaCode}`);
  }

  keepSessionAlive(): Observable<NgResponse<KeepSessionAliveResponse>> {
    return this.get<KeepSessionAliveResponse>('keepSessionAlive', 'api/Security/KeepSessionAlive');
  }

  fetchCounty(countyRequest: NgRequest<FilterSortPageRequest<CountyApiRequest>>): Observable<NgResponse<FilteredCountyApiResponse>> {
    return this.postBody<FilterSortPageRequest<CountyApiRequest>, FilteredCountyApiResponse>('fetchCounty', `api/Security/County`, countyRequest);
  }
  fetchLEA(leaRequest: NgRequest<FilterSortPageRequest<LEAApiRequest>>): Observable<NgResponse<FilteredLEAApiResponse>> {
    return this.postBody<FilterSortPageRequest<LEAApiRequest>, FilteredLEAApiResponse>('fetchLEA', `api/Security/Lea`, leaRequest);
  }
  fetchCharter(charterRequest: NgRequest<FilterSortPageRequest<CharterApiRequest>>): Observable<NgResponse<FilteredCharterApiResponse>> {
    return this.postBody<FilterSortPageRequest<CharterApiRequest>, FilteredCharterApiResponse>('fetchCharter', `api/Security/Charter`, charterRequest);
  }

  fetchCountyFilterValues(countyRequest: NgRequest<FilterValuesRequest<CountyApiRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<CountyApiRequest>, FilterValuesResponse>('fetchCountyFilterValues', 'api/Security/County/FilterValues', countyRequest);
  }

  fetchLEAFilterValues(leaRequest: NgRequest<FilterValuesRequest<LEAApiRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<LEAApiRequest>, FilterValuesResponse>('fetchLEAFilterValues', 'api/Security/Lea/FilterValues', leaRequest);
  }

  fetchCharterFilterValues(charterRequest: NgRequest<FilterValuesRequest<CharterApiRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<CharterApiRequest>, FilterValuesResponse>('fetchCharterFilterValues', 'api/Security/Charter/FilterValues', charterRequest);
  }

  getEntitySubtypeDefinitionValues(): Observable<NgResponse<EntitySubTypeDefinitionResponse>> {
    return this.get<EntitySubTypeDefinitionResponse>('getEntitySubtypeDefinitionValues', `api/Security/EntitySubtypeDefinitionValues`);
  }

  getEntities(caFiscalYear: number, entityType: EntityTypes): Observable<NgResponse<EntitiesListResponse>> {
    return this.get<EntitiesListResponse>('getEntitiesByTypeLight', `api/Security/EntitiesByTypeLight/${caFiscalYear}/${entityType}`);
  }

  fetchLapsedDistrictsItems(request: NgRequest<FilterSortPageRequest<LapsedDistrictsRequest>>): Observable<NgResponse<LapsedDistrictsNgResponse>> {
    return this.postBody<FilterSortPageRequest<LapsedDistrictsRequest>, LapsedDistrictsNgResponse>('fetchLapsedDistrictsItems', `api/LapsedDistricts/Items`, request);
  }

  fetchLapsedDistrictsFilterValues(request: NgRequest<FilterValuesRequest<LapsedDistrictsRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<LapsedDistrictsRequest>, FilterValuesResponse>('fetchLapsedDistrictsFilterValues', 'api/LapsedDistricts/FilterValues', request);
  }

  addLapsedDistrict(lapsedDistrictRequest: NgRequest<LapsedDistrictsRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<LapsedDistrictsRequest, EmptyResponse>('addLapsedDistrict', 'api/LapsedDistricts', lapsedDistrictRequest);
  }

  replaceLapsedDistrict(lapsedDistrictRequest: NgRequest<ReplaceItemRequest<LapsedDistrictsRequest>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<LapsedDistrictsRequest>, EmptyResponse>('replaceLapsedDistrict', 'api/LapsedDistricts/Replace', lapsedDistrictRequest);
  }

  deleteLapsedDistrict(lapsedDistrictDeleteRequest: NgRequest<LapsedDistrictsRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<LapsedDistrictsRequest, EmptyResponse>('deleteLapsedDistrict', 'api/LapsedDistricts/Delete', lapsedDistrictDeleteRequest);
  }
}
