import { BatchJobTypes } from './batch-job-types';
import { ReportingPeriods } from './reporting-periods';
import { SubmissionStates } from './submission-states';
import { SubmissionStateTypes } from './submission-state-types';

export enum TRCGroups {
  All = 'All',
  Import = 'Import',
  GeneralLedger = 'General Ledger',
  Supplemental = 'Supplemental',
  ExportValidation = 'Export Validation'
}

export class BatchDialogData {
  showOfficialSelection: boolean;

  constructor(init?: Partial<BatchDialogData>) {
    Object.assign(this, init);
  }
}

export class BatchDialogResults {
  name: string;
  isOfficial: boolean;

  constructor(init?: Partial<BatchDialogResults>) {
    Object.assign(this, init);
  }
}

export interface IBatchJobParameters { }

export class BatchExportParameters implements IBatchJobParameters {
  submissionNumbers: Array<string>;
  isOfficial: boolean;

  constructor(init?: Partial<BatchExportParameters>) {
    Object.assign(this, init);
  }
}

export class BatchTRCParameters implements IBatchJobParameters {
  submissionNumbers: Array<string>;

  constructor(init?: Partial<BatchTRCParameters>) {
    Object.assign(this, init);
  }
}

export class BatchQuerySACSParameters implements IBatchJobParameters {
  submissionNumbers: Array<string>;
  fullFiscalYear: string;

  constructor(init?: Partial<BatchQuerySACSParameters>) {
    Object.assign(this, init);
  }
}

export class BatchRunReportsParameters implements IBatchJobParameters {
  fullFiscalYears: Array<string>;
  reportingPeriods: Array<ReportingPeriods>;
  submissionStates: Array<SubmissionStates>;
  submissionStateTypes: Array<SubmissionStateTypes>;

  constructor(init?: Partial<BatchRunReportsParameters>) {
    Object.assign(this, init);
  }
}

export class BatchExportUserDataParameters implements IBatchJobParameters {
  fullFiscalYears: Array<string>;
  reportingPeriods: Array<ReportingPeriods>;
  submissionStates: Array<SubmissionStates>;
  submissionStateTypes: Array<SubmissionStateTypes>;
  contextFullFiscalYear: string;

  constructor(init?: Partial<BatchExportUserDataParameters>) {
    Object.assign(this, init);
  }
}

export class BatchJobApiRequest {
  name: string;
  type: BatchJobTypes;
  parameters?: string;

  constructor(init?: Partial<BatchJobApiRequest>) {
    Object.assign(this, init);
  }
}
