import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Observable } from 'rxjs';
import { AppContext } from '../app.context';
import { CharterSchoolApiResponse, CharterSchoolManagement, CharterSchoolManagementApiResponse, ReportingFormatResponse, UpdateCharterIsEditableApiRequest, UpdateReportingStatusesRequest, UpdateReportingStatusesResponse } from '../shared/charter-schools-form.model';
import { AddContactApiRequest, COESoftwareContacts, COESoftwareContactsRequest } from '../shared/coe-software-contact.model';
import { COEContactsManagementListResponse, COEContactsManagementResponse, DeleteCOEContactApiRequest, UpdateAgreementGeneratedApiRequest, UpdateAgreementGeneratedApiResponse, UpdateCOEContactAccessFormReceivedRequest, UpdateCOEContactsManagementRequest, UpdateCOEContactsManagementResponse, UpdateContactIsPrimaryRequest, UpdateMaintainCOEIsEditableApiRequest } from '../shared/contact.model';
import { EmptyRequest } from '../shared/empty-request';
import { EmptyResponse } from '../shared/empty-response';
import { EntityType } from '../shared/entity-type.model';
import { CreateFiscalYearFormatRequest, FiscalYearFormat, FiscalYearReportingPeriodsResponse } from '../shared/fiscal-year.model';
import { FilterSortPageRequest, FilterValuesRequest, FilterValuesResponse } from '../shared/grid.model';
import { NgMessage } from '../shared/ng-message';
import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';
import { AddNoteRequest, NotesResponse } from '../shared/notes.model';
import { ReplaceItemRequest } from '../shared/replace-item-request';
import { ReportingPeriod, UpdateReportingPeriodsRequest, UpdateReportingPeriodsResponse } from '../shared/reporting-periods.model';
import { Role } from '../shared/role.model';
import { ScreenPermissionsRequest, ScreenPermissionsResponse } from '../shared/screen-permissions.model';
import { FeatureDisable, UploadAttachmentDetails } from '../shared/security.model';
import { StateList } from '../shared/state-list.model';
import { SystemVersion } from '../shared/system-version.model';
import { AgreementAndInstructionalTextResponse, AgreementTextApiResponse, InstructionalTextApiResponse, UAPromoterFormPrintRequest, UpdateAgreementTextRequest, UpdateInstructionalTextRequest } from '../shared/ua-promoter-request-form-model';
import { ValidCodeTitle, ValidCodeTitleNgRequest, ValidEntityTypesResponse } from '../shared/valid-code.model';
import { ZipFilesManagementResponse } from '../shared/zip-files-management.model';
import { ApprovedICStraightRateRequest, ApprovedICStraightRateResponse } from "../tools/approved-icstraight-rate/approved-icstraight-rate.model";
import { CharterSchoolsFinancialReportingStatusPrintRequest } from '../tools/charter-schools-financial-reporting-status/charter-schools-financial-reporting-status-print-request';
import { CodeCombination, CodeCombinationsRequest, CodeCombinationsResponse } from '../tools/code-combinations/code-combinations.model';
import { COEContactManagementResponse, COEContactsManagementExportRequest, COEContactsResponse } from '../tools/coe-contacts-management/coe-contacts-management.model';
import { DescriptionList, DescriptionListsRequest, DescriptionListsResponse } from '../tools/description-lists/description-lists.model';
import { ICRateDifference } from '../tools/ic-rate-differences/ic-rate-differences.model';
import { R31000Request, R31000Response } from '../tools/maintain-promote-to-cde-roles/r31000.model';
import { PCAResourceManagementResponse, PCAResourceManagementViewModel, Resource } from '../tools/pca-resource-management/pca-resource-management.model';
import { ValidCodeCombinationManagementExportRequest } from '../tools/valid-code-and-combination-management/valid-code-combinations-management.model';
import { CompositeAddValidCodeCombinationItem, ValidCodeCombination, ValidCodeCombinationEntityType, ValidCodeCombinationEntityTypesNgRequest, ValidCodeCombinationEntityTypesResponse, ValidCodeCombinationsResponse } from '../tools/valid-code-and-combination-management/ValidCodeCombinations/valid-code-combinations-management.model';
import { ValidCodeAddEditItem } from '../tools/valid-code-and-combination-management/ValidCodes/valid-code-add-edit/add-edit.model';
import { ValidCode, ValidCodeEntityType, ValidCodeEntityTypeDetailsNgRequest, ValidCodeEntityTypeTitleDescription, ValidCodeEntityTypeTitlesDescriptionsResponse, ValidCodeEntityTypesNgRequest, ValidCodeEntityTypesResponse, ValidCodesResponse } from '../tools/valid-code-and-combination-management/ValidCodes/valid-codes.model';
import { DeleteContactResponse, EditContactApiRequest } from './../shared/coe-software-contact.model';
import { UASubmissionChecklist, UASubmissionChecklistStep } from './../tools/ua-submission-checklist/ua-submission-checklist.model';
import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';

class ReferenceDataNgResponse {
  roles: Array<Role>;
  messages: Array<NgMessage>;
  entityTypes: Array<EntityType>;
  states: Array<StateList>;
  systemVersion: SystemVersion;
  buildVersion: string;
  featureDisable: FeatureDisable;
  fiscalYears: Array<FiscalYearFormat>;
  uploadFileAttachmentDetails: UploadAttachmentDetails
}

@Injectable()
export class ReferenceDataService extends HttpClientService {
  constructor(readonly httpClient: HttpClient,
    readonly appContext: AppContext,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('ReferenceDataService', httpClient, httpErrorHandler);
  }

  init(): Observable<any> {
    return new Observable(observer => {
      this.get<ReferenceDataNgResponse>(
        'messages',
        this.appContext.runMode
          ? `api/ReferenceData/ReferenceData/${this.appContext.runMode}`
          : 'api/ReferenceData/ReferenceData')
        .subscribe(ngResponse => {
          this.appContext.setReferenceData(
            ngResponse.response.roles,
            ngResponse.response.messages,
            ngResponse.response.entityTypes,
            ngResponse.response.states,
            ngResponse.response.systemVersion,
            ngResponse.response.buildVersion,
            ngResponse.response.featureDisable,
            ngResponse.response.fiscalYears,
            ngResponse.response.uploadFileAttachmentDetails);
          observer.next(this);
          observer.complete();
        });
    });
  }

  getAppInsightsKey(): Observable<any> {
    return new Observable(observer => {
      this.get<string>('AppInsightsKey', 'api/ReferenceData/AppInsightsKey')
        .subscribe(ngResponse => {
          this.appContext.appInsights = new ApplicationInsights({
            config: {
              instrumentationKey: ngResponse.response,
              enableAutoRouteTracking: true
            }
          });
          this.appContext.appInsights.loadAppInsights();
          observer.next(this);
          observer.complete();
        });
    });
  }

  getCharterSchoolsInformation(caFiscalYear: number, isSBEView: boolean): Observable<NgResponse<CharterSchoolManagementApiResponse>> {
    return this.get<CharterSchoolManagementApiResponse>('getCharterSchoolsInformation', `api/ReferenceData/GetCharterSchoolsInformation/${caFiscalYear}/${isSBEView}`);
  }

  exportCharterSchoolsInformationExcel(caFiscalYear: number, isSBEView: boolean): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('exportCharterSchoolsInformationExcel', `api/ReferenceData/GetCharterSchoolsInformation/Excel/${caFiscalYear}/${isSBEView}`);
  }

  getCharterSchools(countyCode: string, districtCode: string, caFiscalYear: number): Observable<NgResponse<CharterSchoolManagementApiResponse>> {
    return this.get<CharterSchoolManagementApiResponse>('getCharters', `api/ReferenceData/GetCharterSchools/${countyCode}/${districtCode}/${caFiscalYear}`);
  }

  getReportingFormatList(): Observable<NgResponse<ReportingFormatResponse>> {
    return this.get<ReportingFormatResponse>('getReportingFormats', `api/ReferenceData/GetReportingFormats`);
  }

  updateReportingStatuses(updateReportingStatusesRequest: NgRequest<UpdateReportingStatusesRequest>): Observable<NgResponse<UpdateReportingStatusesResponse>> {
    return this.postBody<UpdateReportingStatusesRequest, UpdateReportingStatusesResponse>('updateReportingStatuses', 'api/ReferenceData/UpdateReportingStatuses', updateReportingStatusesRequest);
  }

  getCOEContacts(cdsCode: string, caFiscalYear: string): Observable<NgResponse<COEContactsManagementResponse>> {
    return this.get<COEContactsManagementResponse>('fetchCOEContacts', `api/ReferenceData/GetCOEContacts/${cdsCode}/${caFiscalYear}`);
  }

  fetchCOEContactManagementItems(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<NgResponse<COEContactManagementResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, COEContactManagementResponse>('fetchCOEContactManagementItems', `api/COEContacts/Management/Items`, request);
  }

  fetchCOEContactManagementFilterValues(request: NgRequest<FilterValuesRequest<EmptyRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchCOEContactManagementFilterValues', 'api/COEContacts/Management/FilterValues', request);
  }

  fetchCOEContactsItems(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<NgResponse<COEContactsResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, COEContactsResponse>('fetchCOEContactsItems', `api/COEContacts/Contacts/Items`, request);
  }

  fetchCOEContactsFilterValues(request: NgRequest<FilterValuesRequest<EmptyRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchCOEContactsFilterValues', 'api/COEContacts/Contacts/FilterValues', request);
  }

  fullExportCOEContactsManagement(): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('fullExportExcelCOEContactsManagementContacts', 'api/COEContacts/Export/Full');
  }

  partialExportCOEContactsManagement(request: NgRequest<COEContactsManagementExportRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<COEContactsManagementExportRequest>('partialExportExcelCOEContactsManagementContacts', 'api/COEContacts/Export/Partial', request);
  }

  fullImportCOEContactsManagement(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('fullImportCOEContactsManagement', 'api/COEContacts/Import/Full', formData);
  }

  partialImportCOEContactsManagement(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('partialImportCOEContactsManagement', 'api/COEContacts/Import/Partial', formData);
  }

  getCOEContactsByCounty(cCode: string): Observable<NgResponse<COEContactsManagementListResponse>> {
    return this.get<COEContactsManagementListResponse>('fetchCOEContactsByCounty', `api/ReferenceData/GetCOEContacts/ByCounty/${cCode}`);
  }

  getCharterIsEditable(countyCode: string): Observable<NgResponse<CharterSchoolApiResponse>> {
    return this.get<CharterSchoolApiResponse>('getCharterIsEditable', `api/ReferenceData/GetCharterIsEditable/${countyCode}`);
  }

  getCOEIsEditable(countyCode: string): Observable<NgResponse<COEContactsManagementResponse>> {
    return this.get<COEContactsManagementResponse>('getCOEIsEditable', `api/ReferenceData/GetCOEIsEditable/${countyCode}`);
  }

  updateCOEContacts(updateCOEContactsRequest: NgRequest<UpdateCOEContactsManagementRequest>): Observable<NgResponse<UpdateCOEContactsManagementResponse>> {
    return this.postBody<UpdateCOEContactsManagementRequest, UpdateCOEContactsManagementResponse>('updateCOEContacts', 'api/ReferenceData/UpdateCOEContacts', updateCOEContactsRequest);
  }

  deleteCOEContact(request: NgRequest<DeleteCOEContactApiRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<DeleteCOEContactApiRequest, EmptyResponse>('deleteCOEContact', `api/ReferenceData/DeleteCOEContact`, request);
  }

  updateContactIsPrimary(request: NgRequest<UpdateContactIsPrimaryRequest>) {
    return this.putBody('updateContactIsPrimary', `api/ReferenceData/UpdateContactIsPrimary`, request);
  }

  updateCharterSchoolManagementIsEditable(request: NgRequest<UpdateCharterIsEditableApiRequest>) {
    return this.putBody('updateCharterSchoolManagementIsEditable', `api/ReferenceData/UpdateCharterSchoolManagementIsEditable`, request);
  }

  updateCOEContactsManagementIsEditable(request: NgRequest<UpdateMaintainCOEIsEditableApiRequest>) {
    return this.putBody('updateCOEContactsManagementIsEditable', `api/ReferenceData/UpdateCOEContactsManagamentIsEditable`, request);
  }

  updateContactAccessFormReceived(request: NgRequest<UpdateCOEContactAccessFormReceivedRequest>): Observable<NgResponse<UpdateCOEContactAccessFormReceivedRequest>> {
    return this.postBody<UpdateCOEContactAccessFormReceivedRequest, UpdateCOEContactAccessFormReceivedRequest>('updateContactAccessFormReceived', 'api/ReferenceData/UpdateAccessFormReceived', request);
  }

  updateAgreementGenerated(updateAgreementGeneratedRequest: NgRequest<UpdateAgreementGeneratedApiRequest>): Observable<NgResponse<UpdateAgreementGeneratedApiResponse>> {
    return this.putBody<UpdateAgreementGeneratedApiRequest, UpdateAgreementGeneratedApiResponse>('updateAgreementGenerated', 'api/ReferenceData/UpdateAgreementGenerated', updateAgreementGeneratedRequest);
  }

  getAgreementAndInstructionalText(): Observable<NgResponse<AgreementAndInstructionalTextResponse>> {
    return this.get<AgreementAndInstructionalTextResponse>('getAgreementAndInstructionalText', `api/ReferenceData/GetAgreementAndInstructionalText`);
  }

  updateAgreementText(updateAgreementTextRequest: NgRequest<UpdateAgreementTextRequest>): Observable<NgResponse<AgreementTextApiResponse>> {
    return this.putBody<UpdateAgreementTextRequest, AgreementAndInstructionalTextResponse>('updateAgreementText', `api/ReferenceData/UpdateAgreementText`, updateAgreementTextRequest);
  }

  updateInstructionalText(updateInstructionalTextRequest: NgRequest<UpdateInstructionalTextRequest>): Observable<NgResponse<InstructionalTextApiResponse>> {
    return this.putBody<UpdateInstructionalTextRequest, AgreementAndInstructionalTextResponse>('updateAgreementText', `api/ReferenceData/UpdateInstructionalText`, updateInstructionalTextRequest);
  }

  fetchDescriptionsLists(request: NgRequest<FilterSortPageRequest<DescriptionListsRequest>>): Observable<NgResponse<DescriptionListsResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, DescriptionListsResponse>('fetchDescriptionLists', `api/DescriptionLists/Items`, request);
  }

  fetchDescriptionListsFilterValues(request: NgRequest<FilterValuesRequest<DescriptionListsRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchDescriptionListsFilterValues', 'api/DescriptionLists/FilterValues', request);
  }

  fetchCodeCombinations(request: NgRequest<FilterSortPageRequest<CodeCombinationsRequest>>): Observable<NgResponse<CodeCombinationsResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, CodeCombinationsResponse>('fetchCodeCombinations', 'api/CodeCombinations/Items', request);
  }

  fetchCodeCombinationsFilterValues(request: NgRequest<FilterValuesRequest<CodeCombinationsRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchCodeCombinationsFilterValues', 'api/CodeCombinations/FilterValues', request);
  }

  getAllFiscalYears(): Observable<NgResponse<Array<FiscalYearFormat>>> {
    return this.get<Array<FiscalYearFormat>>('getAllFiscalYears', 'api/ReferenceData/AllFiscalYears');
  }

  getActiveFiscalYears(): Observable<NgResponse<FiscalYearFormat[]>> {
    return this.get<FiscalYearFormat[]>('getActiveFiscalYears', 'api/ReferenceData/ActiveFiscalYears');
  }

  getReportingPeriods(caFiscalYear: number): Observable<NgResponse<ReportingPeriod[]>> {
    return this.get<ReportingPeriod[]>('getReportingPeriods', `api/ReferenceData/ReportingPeriodsData/${caFiscalYear}`);
  }

  getValidCodeTitlesList(request: NgRequest<ValidCodeTitleNgRequest>): Observable<NgResponse<ValidCodeTitle[]>> {
    return this.postBody<ValidCodeTitleNgRequest, ValidCodeTitle[]>('getValidCodeTitlesList', 'api/ReferenceData/ValidCodeTitlesList', request);
  }

  getValidEntityTypes(): Observable<NgResponse<ValidEntityTypesResponse>> {
    return this.get<ValidEntityTypesResponse>('getValidEntityTypes', 'api/ReferenceData/GetValidEntityTypes');
  }

  getValidEntitySubTypes(): Observable<NgResponse<ValidEntityTypesResponse>> {
    return this.get<ValidEntityTypesResponse>('getValidEntitySubTypes', 'api/ReferenceData/GetValidEntitySubTypes');
  }

  getICRateDifferences(): Observable<NgResponse<ICRateDifference[]>> {
    return this.post<ICRateDifference[]>('getICRateDifferences', 'api/ReferenceData/GetICRateDifferences');
  }

  getNotes(parentId: string): Observable<NgResponse<NotesResponse>> {
    return this.get<NotesResponse>('getNotes', `api/ReferenceData/Notes/${parentId}`);
  }

  addNote(request: NgRequest<AddNoteRequest>): Observable<NgResponse<NotesResponse>> {
    return this.postBody<AddNoteRequest, NotesResponse>('addNote', `api/ReferenceData/Notes`, request);
  }
  updateNote(request: NgRequest<AddNoteRequest>): Observable<NgResponse<NotesResponse>> {
    return this.postBody<AddNoteRequest, NotesResponse>('updateNote', `api/ReferenceData/UpdateNotes`, request);
  }
  removeCertifyPromoteToCDERoles(cdsCode: string): Observable<NgResponse<COEContactsManagementListResponse>> {
    return this.put<COEContactsManagementListResponse>('removeCertifyPromoteToCDERoles', `api/ReferenceData/RemoveCertifyPromoteToCDERoles/${cdsCode}`);
  }

  updateCharterSchool(request: NgRequest<CharterSchoolManagement>): Observable<NgResponse<CharterSchoolManagement>> {
    return this.putBody<CharterSchoolManagement, CharterSchoolManagement>('updateCharterSChool', `api/ReferenceData/CharterSchool`, request);
  }

  updateReportingPeriods(updateReportingPeriodsRequest: NgRequest<UpdateReportingPeriodsRequest>): Observable<NgResponse<UpdateReportingPeriodsResponse>> {
    return this.putBody<UpdateReportingPeriodsRequest, UpdateReportingPeriodsResponse>('updateReportingPeriods', 'api/ReferenceData/UpdateReportingPeriods', updateReportingPeriodsRequest);
  }

  updateDescriptionDetail(codeType: string, descriptionList: NgRequest<DescriptionList>): Observable<NgResponse<DescriptionList>> {
    return this.putBody<DescriptionList, DescriptionList>('updateDescriptionDetail', `api/ReferenceData/UpdateDescriptionDetail/${codeType}`, descriptionList);
  }

  getValidCodes(codeCombinationType: string): Observable<NgResponse<string[]>> {
    return this.get<string[]>('getValidCodes', `api/ReferenceData/GetValidCodes/${codeCombinationType}`);
  }

  deleteCodeCombination(codeCombinationType: string, validCode1: string, validCode2: string): Observable<NgResponse<CodeCombination>> {
    return this.delete<CodeCombination>('deleteValidCodeCombination', `api/ReferenceData/DeleteValidCodeCombination/${codeCombinationType}/${validCode1}/${validCode2}`);
  }

  createFiscalYear(request: NgRequest<CreateFiscalYearFormatRequest>): Observable<NgResponse<FiscalYearFormat>> {
    return this.postBody<CreateFiscalYearFormatRequest, FiscalYearFormat>('createFiscalYear', 'api/ReferenceData/CreateFiscalYear/', request);
  }

  getNextFiscalYearData(): Observable<NgResponse<FiscalYearReportingPeriodsResponse>> {
    return this.get<FiscalYearReportingPeriodsResponse>('getNextFiscalYearsData', 'api/ReferenceData/GetNextFiscalYearData/');
  }

  getScreenPermissions(caFiscalYear: number): Observable<NgResponse<ScreenPermissionsResponse>> {
    return this.get<ScreenPermissionsResponse>('getScreenPermissions', `api/ReferenceData/ScreenPermissions/${caFiscalYear}`);
  }

  updateScreenPermissions(screenPermissionsRequest: NgRequest<ScreenPermissionsRequest>): Observable<NgResponse<ScreenPermissionsResponse>> {
    return this.putBody<ScreenPermissionsRequest, ScreenPermissionsResponse>('UpdateScreenPermissions', 'api/ReferenceData/UpdateScreenPermissions', screenPermissionsRequest);
  }

  addScreenPermissions(screenPermissionsRequest: NgRequest<ScreenPermissionsRequest>): Observable<NgResponse<ScreenPermissionsResponse>> {
    return this.postBody<ScreenPermissionsRequest, ScreenPermissionsResponse>('addScreenPermissions', 'api/ReferenceData/AddScreenPermissions', screenPermissionsRequest);
  }

  public fetchItemsR31000(request: NgRequest<FilterSortPageRequest<R31000Request>>): Observable<NgResponse<R31000Response>> {
    return this.postBody<FilterSortPageRequest<R31000Request>, R31000Response>(
      'fetchItemsR31000', `api/Report/31000/Items`, request
    );
  }

  public fetchFilterValuesR31000(request: NgRequest<FilterValuesRequest<R31000Request>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<R31000Request>, FilterValuesResponse>('fetchFilterValuesR31000', 'api/Report/31000/FilterValues', request);
  }

  public fetchFilterValuesR32000(request: NgRequest<FilterValuesRequest<R31000Request>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<R31000Request>, FilterValuesResponse>('fetchFilterValuesR31000', 'api/Report/32000/FilterValues', request);
  }

  uaPromoterRequestFormPrintPreview(printRequest: NgRequest<UAPromoterFormPrintRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<UAPromoterFormPrintRequest>('uaPromoterRequestFormPrintPreview', 'api/UAPromoterRequestForm/Preview', printRequest);
  }

  charterSchoolsFinancialReportingStatusPrintPreview(printRequest: NgRequest<CharterSchoolsFinancialReportingStatusPrintRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<CharterSchoolsFinancialReportingStatusPrintRequest>('charterSchoolsFinancialReportingStatusPrintPreview', 'api/ReferenceData/CharterSchoolsFinancialReportingStatus/Preview', printRequest);
  }

  fetchCOESoftwareContactItems(contactsRequest: NgRequest<FilterSortPageRequest<COESoftwareContactsRequest>>): Observable<NgResponse<COESoftwareContacts[]>> {
    return this.postBody<FilterSortPageRequest<COESoftwareContactsRequest>, COESoftwareContacts[]>(
      'fetchCOESoftwareContentItems',
      `api/ReferenceData/COESoftwareContactItems`,
      contactsRequest
    );
  }

  fetchCOESoftwareContactFilterValues(request: NgRequest<FilterValuesRequest<COESoftwareContactsRequest>>) {
    return this.postBody<FilterValuesRequest<COESoftwareContactsRequest>, FilterValuesResponse>(
      'fetchCOESoftwareContactFilterValues',
      `api/ReferenceData/COESoftwareContactFilterValues`,
      request
    );
  }

  addContactData(request: NgRequest<AddContactApiRequest>): Observable<NgResponse<COESoftwareContacts>> {
    return this.postBody<AddContactApiRequest, COESoftwareContacts>('createContact', 'api/ReferenceData/AddCOESoftwareContact', request);
  }

  editContactData(request: NgRequest<EditContactApiRequest>): Observable<NgResponse<COESoftwareContacts>> {
    return this.putBody<EditContactApiRequest, COESoftwareContacts>('updateContact', 'api/ReferenceData/EditCOESoftwareContact', request);
  }

  deleteContactData(id: string): Observable<NgResponse<DeleteContactResponse>> {
    return this.delete<DeleteContactResponse>('deleteContact', `api/ReferenceData/DeleteCOESoftwareContact/${id}`);
  }

  fetchApprovedICStraightRateItems(request: NgRequest<FilterSortPageRequest<ApprovedICStraightRateRequest>>): Observable<NgResponse<ApprovedICStraightRateResponse>> {
    return this.postBody<FilterSortPageRequest<ApprovedICStraightRateRequest>, ApprovedICStraightRateResponse>('fetchApprovedICStraightRateItems', `api/ApprovedICStraightRate/Items`, request);
  }

  fetchApprovedICStraightRateFilterValues(request: NgRequest<FilterValuesRequest<ApprovedICStraightRateRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<ApprovedICStraightRateRequest>, FilterValuesResponse>('fetchApprovedICStraightRateFilterValues', 'api/ApprovedICStraightRate/FilterValues', request);
  }

  fullExportApprovedICStraightRate(): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('exportApprovedICStraightRate', 'api/ApprovedICStraightRate/Export/Full');
  }

  partialExportApprovedICStraightRate(request: NgRequest<FilterSortPageRequest<ApprovedICStraightRateRequest>>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<ApprovedICStraightRateRequest>('exportApprovedICStraightRate', `api/ApprovedICStraightRate/Export/Partial`, request);
  }

  fullImportApprovedICStraightRate(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('fullImportApprovedICStraightRate', 'api/ApprovedICStraightRate/Import/Full', formData);
  }

  partialImportApprovedICStraightRate(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('partialImportApprovedICStraightRate', 'api/ApprovedICStraightRate/Import/Partial', formData);
  }

  getUASubmissionChecklist(): Observable<NgResponse<UASubmissionChecklist>> {
    return this.get<UASubmissionChecklist>('getUASubmissionChecklist', `api/ReferenceData/UASubmissionChecklist`);
  }

  updateUASubmissionChecklistStep(request: NgRequest<UASubmissionChecklistStep>): Observable<NgResponse<EmptyResponse>> {
    return this.putBody<UASubmissionChecklistStep, EmptyResponse>('updateUASubmissionChecklistStep', `api/ReferenceData/UASubmissionChecklistStep`, request);
  }

  clearUASubmissionChecklistSteps(): Observable<NgResponse<EmptyResponse>> {
    return this.delete<EmptyResponse>('clearUASubmissionChecklistSteps', `api/ReferenceData/UASubmissionChecklistStep`);
  }

  fetchValidCodesItems(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<NgResponse<ValidCodesResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, ValidCodesResponse>('fetchValidCodeItems', `api/ValidCodeAndCombinationManagement/ValidCodes/Items`, request);
  }

  fetchValidCodeCombinationsItems(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<NgResponse<ValidCodeCombinationsResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, ValidCodeCombinationsResponse>('fetchValidCodeCombinationItems', `api/ValidCodeAndCombinationManagement/ValidCodeCombinations/Items`, request);
  }

  fetchValidCodesFilterValues(request: NgRequest<FilterValuesRequest<EmptyRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchValidCodesFilterValues', `api/ValidCodeAndCombinationManagement/ValidCodes/FilterValues`, request);
  }

  fetchValidCodeCombinationsFilterValues(request: NgRequest<FilterValuesRequest<EmptyRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchValidCodeCombinationsFilterValues', `api/ValidCodeAndCombinationManagement/ValidCodeCombinations/FilterValues`, request);
  }

  replaceValidCodeCombination(replaceValidCodeCombinationRequest: NgRequest<ReplaceItemRequest<ValidCodeCombination>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<ValidCodeCombination>, EmptyResponse>('replaceValidCodeCombination', 'api/ValidCodeAndCombinationManagement/ValidCodeCombinations/Replace', replaceValidCodeCombinationRequest);
  }

  compositeAddValidCodeCombination(compositeAddValidCodeCombinationRequest: NgRequest<CompositeAddValidCodeCombinationItem>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<CompositeAddValidCodeCombinationItem, EmptyResponse>('compositeAddValidCodeCombination', 'api/ValidCodeAndCombinationManagement/ValidCodeCombinations/Composite', compositeAddValidCodeCombinationRequest);
  }

  compositeAddValidCodeCombinationEntityType(compositeAddValidCodeCombinationRequest: NgRequest<CompositeAddValidCodeCombinationItem>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<CompositeAddValidCodeCombinationItem, EmptyResponse>('compositeAddValidCodeCombination', 'api/ValidCodeAndCombinationManagement/ValidCodeCombinations/EntityTypes/Composite', compositeAddValidCodeCombinationRequest);
  }

  fetchValidCodeEntityTypeItems(request: NgRequest<FilterSortPageRequest<ValidCodeEntityTypesNgRequest>>): Observable<NgResponse<ValidCodeEntityTypesResponse>> {
    return this.postBody<FilterSortPageRequest<ValidCodeEntityTypesNgRequest>, ValidCodeEntityTypesResponse>('fetchValidCodeEntityTypeItems', `api/ValidCodeAndCombinationManagement/ValidCodes/EntityTypes/Items`, request);
  }

  fetchValidCodeEntityTypeFilterValues(request: NgRequest<FilterSortPageRequest<ValidCodeEntityTypesNgRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterSortPageRequest<ValidCodeEntityTypesNgRequest>, FilterValuesResponse>('fetchValidCodeEntityTypeFilterValues', `api/ValidCodeAndCombinationManagement/ValidCodes/EntityTypes/FilterValues`, request);
  }

  fetchValidCodeCombinationEntityTypeItems(request: NgRequest<FilterSortPageRequest<ValidCodeCombinationEntityTypesNgRequest>>): Observable<NgResponse<ValidCodeCombinationEntityTypesResponse>> {
    return this.postBody<FilterSortPageRequest<ValidCodeCombinationEntityTypesNgRequest>, ValidCodeCombinationEntityTypesResponse>('fetchValidCodeCombinationEntityTypeItems', `api/ValidCodeAndCombinationManagement/ValidCodeCombinations/EntityTypes/Items`, request);
  }

  fetchValidCodeCombinationEntityTypeFilterValues(request: NgRequest<FilterSortPageRequest<ValidCodeCombinationEntityTypesNgRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterSortPageRequest<ValidCodeCombinationEntityTypesNgRequest>, FilterValuesResponse>('fetchValidCodeCombinationEntityTypeFilterValues', `api/ValidCodeAndCombinationManagement/ValidCodeCombinations/EntityTypes/FilterValues`, request);
  }

  replaceValidCodeCombinationEntityType(replaceValidCodeCombinationEntityTypeRequest: NgRequest<ReplaceItemRequest<ValidCodeCombinationEntityType>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<ValidCodeCombinationEntityType>, EmptyResponse>('replaceValidCodeCombinationEntityType', 'api/ValidCodeAndCombinationManagement/ValidCodeCombinations/EntityTypes/Replace', replaceValidCodeCombinationEntityTypeRequest);
  }

  fetchValidCodeEntityTypeTitleDescriptionItems(request: NgRequest<FilterSortPageRequest<ValidCodeEntityTypeDetailsNgRequest>>): Observable<NgResponse<ValidCodeEntityTypeTitlesDescriptionsResponse>> {
    return this.postBody<FilterSortPageRequest<ValidCodeEntityTypeDetailsNgRequest>, ValidCodeEntityTypeTitlesDescriptionsResponse>('fetchValidCodeEntityTypeTitleDescriptionItems', `api/ValidCodeAndCombinationManagement/ValidCodes/EntityTypes/TitlesDescriptions/Items`, request);
  }

  fetchValidCodeEntityTypeTitleDescriptionFilterValues(request: NgRequest<FilterSortPageRequest<ValidCodeEntityTypeDetailsNgRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterSortPageRequest<ValidCodeEntityTypeDetailsNgRequest>, FilterValuesResponse>('fetchValidCodeEntityTypeTitleDescriptionFilterValues', `api/ValidCodeAndCombinationManagement/ValidCodes/EntityTypes/TitlesDescriptions/FilterValues`, request);
  }

  addValidCodeEntityTypeTitleDescription(addValidCodeEntityTypeTitleDescriptionRequest: NgRequest<ValidCodeEntityTypeTitleDescription>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ValidCodeEntityTypeTitleDescription, EmptyResponse>('addValidCodeEntityTypeTitleDescription', 'api/ValidCodeAndCombinationManagement/ValidCodes/EntityTypes/TitlesDescriptions', addValidCodeEntityTypeTitleDescriptionRequest);
  }

  replaceValidCodeEntityTypeTitleDescription(validCodeEntityTypeTitleDescriptionReplaceRequest: NgRequest<ReplaceItemRequest<ValidCodeEntityTypeTitleDescription>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<ValidCodeEntityTypeTitleDescription>, EmptyResponse>('replacetitleDescription', 'api/ValidCodeAndCombinationManagement/ValidCodes/EntityTypes/TitlesDescriptions/Replace', validCodeEntityTypeTitleDescriptionReplaceRequest);
  }

  deleteValidCodeEntityTypeTitleDescription(titleDescriptionDeleteRequest: NgRequest<ValidCodeEntityTypeTitleDescription>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ValidCodeEntityTypeTitleDescription, EmptyResponse>('deleteValidCodeEntityTypeTitleDescription', 'api/ValidCodeAndCombinationManagement/ValidCodes/EntityTypes/TitlesDescriptions/Delete', titleDescriptionDeleteRequest);
  }

  replaceValidCodeEntityType(replaceValidCodeEntityTypeRequest: NgRequest<ReplaceItemRequest<ValidCodeEntityType>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<ValidCodeEntityType>, EmptyResponse>('replaceValidCodeEntityType', 'api/ValidCodeAndCombinationManagement/ValidCodes/EntityTypes/Replace', replaceValidCodeEntityTypeRequest);
  }

  deleteValidCodeEntityType(deleteValidCodeEntityTypeRequest: NgRequest<ValidCodeEntityType>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ValidCodeEntityType, EmptyResponse>('deleteValidCodeEntityType', 'api/ValidCodeAndCombinationManagement/ValidCodes/EntityTypes/Delete', deleteValidCodeEntityTypeRequest);
  }

  replaceValidCode(replaceValidCodeRequest: NgRequest<ReplaceItemRequest<ValidCode>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<ValidCode>, EmptyResponse>('replaceValidCode', 'api/ValidCodeAndCombinationManagement/ValidCodes/Replace', replaceValidCodeRequest);
  }

  updateValidCode(updateValidCodeRequest: NgRequest<ValidCode>): Observable<NgResponse<EmptyResponse>> {
    return this.putBody<ValidCode, EmptyResponse>('updateValidCode', 'api/ValidCodeAndCombinationManagement/ValidCodes', updateValidCodeRequest);
  }

  deleteValidCode(deleteValidCodeRequest: NgRequest<ValidCode>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ValidCode, EmptyResponse>('deleteValidCode', 'api/ValidCodeAndCombinationManagement/ValidCodes/Delete', deleteValidCodeRequest);
  }

  deleteValidCodeCombination(deleteValidCodeCombinationRequest: NgRequest<ValidCodeCombination>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ValidCodeCombination, EmptyResponse>('deleteValidCodeCombination', 'api/ValidCodeAndCombinationManagement/ValidCodeCombinations/Delete', deleteValidCodeCombinationRequest);
  }

  deleteValidCodeCombinationEntityType(deleteValidCodeCombinationEntityTypeRequest: NgRequest<ValidCodeCombinationEntityType>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ValidCodeCombinationEntityType, EmptyResponse>('deleteValidCodeCombinationEntityType', 'api/ValidCodeAndCombinationManagement/ValidCodeCombinations/EntityTypes/Delete', deleteValidCodeCombinationEntityTypeRequest);
  }

  fullExportValidCodeCombinationManagement(): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('fullExportValidCodeCombinationManagement', 'api/ValidCodeAndCombinationManagement/Export/Full');
  }

  partialExportValidCodeCombinationManagement(request: NgRequest<ValidCodeCombinationManagementExportRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<ValidCodeCombinationManagementExportRequest>('partialExportValidCodeCombinationManagement', 'api/ValidCodeAndCombinationManagement/Export/Partial', request);
  }

  fullImportValidCodeCombinationManagement(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('fullImportValidCodeCombinationManagement', 'api/ValidCodeAndCombinationManagement/Import/Full', formData);
  }

  partialImportValidCodeCombinationManagement(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('partialImportCOEContactsManagement', 'api/ValidCodeAndCombinationManagement/Import/Partial', formData);
  }

  fetchPCAResourceManagementItems(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<NgResponse<PCAResourceManagementResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, PCAResourceManagementResponse>('fetchPCAResourceManagementItems', `api/PCAResourceManagement/Items`, request);
  }
  fetchPCAResourceManagementFilterValues(request: NgRequest<FilterValuesRequest<EmptyRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchPCAResourceManagementFilterValues', 'api/PCAResourceManagement/FilterValues', request);
  }

  addPCAResourceManagementItem(request: NgRequest<PCAResourceManagementViewModel>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<PCAResourceManagementViewModel, EmptyResponse>('addPCAResourceManagementItem', `api/PCAResourceManagement`, request);
  }

  updatePCAResourceManagementItem(request: NgRequest<PCAResourceManagementViewModel>): Observable<NgResponse<EmptyResponse>> {
    return this.putBody<PCAResourceManagementViewModel, EmptyResponse>('addPCAResourceManagementItem', `api/PCAResourceManagement`, request);
  }

  replacePCAResourceManagementItem(request: NgRequest<ReplaceItemRequest<PCAResourceManagementViewModel>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<PCAResourceManagementViewModel>, EmptyResponse>('replacePCAResourceManagementItem', 'api/PCAResourceManagement/Replace', request);
  }

  fullExportPCAResourceManagement(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<FilterSortPageRequest<EmptyRequest>>('partialPCAResourceManagement', 'api/PCAResourceManagement/Export/Full', request);
  }

  partialExportPCAResourceManagement(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<FilterSortPageRequest<EmptyRequest>>('partialPCAResourceManagement', 'api/PCAResourceManagement/Export/Partial', request);
  }

  partialImportPCAResourceManagement(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('partialImportPCAResourceManagement', 'api/PCAResourceManagement/Import/Partial', formData);
  }

  getSuggestedPCANumber(): Observable<NgResponse<string>> {
    return this.get<string>('getSuggestedPCANumber', `api/PCAResourceManagement/NextPCANumber`);
  }

  getStaffFullNames(): Observable<NgResponse<string[]>> {
    return this.get<string[]>('getStaffFullNames', `api/PCAResourceManagement/StaffFullNames`);
  }

  getResources(): Observable<NgResponse<Resource[]>> {
    return this.get<Resource[]>('getResources', `api/PCAResourceManagement/Resources`);
  }

  addValidCode(addValidCodeRequest: NgRequest<ValidCodeAddEditItem>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ValidCodeAddEditItem, EmptyResponse>('addValidCode', 'api/ValidCodeAndCombinationManagement/ValidCodes/Composite', addValidCodeRequest);
  }

  addValidCodeEntityTypes(addValidCodeEntityTypesRequest: NgRequest<ValidCodeAddEditItem>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ValidCodeAddEditItem, EmptyResponse>('addValidCodeEntityTypes', 'api/ValidCodeAndCombinationManagement/ValidCodes/EntityTypes/Composite', addValidCodeEntityTypesRequest);
  }

  createZipFiles(created: Date, timeZoneId: string, lastUpdated: Date, fullFiscalYear: string, includePriorYear: boolean, matrixUpdatesDocument: File): Observable<NgResponse<ZipFilesManagementResponse>> {
    const createdValue = encodeURIComponent(created.toISOString());
    const timeZoneIdValue = encodeURIComponent(timeZoneId);
    const lastUpdatedValue = encodeURIComponent(lastUpdated.toISOString());

    const formData: FormData = new FormData();
    formData.append('matrixUpdatesDocument', matrixUpdatesDocument, matrixUpdatesDocument.name);

    return this.postFile<ZipFilesManagementResponse>('createZipFiles', `api/ZipFilesManagement?created=${createdValue}&timeZoneId=${timeZoneIdValue}&lastUpdated=${lastUpdatedValue}&fullFiscalYear=${fullFiscalYear}&includePriorYear=${includePriorYear}`, formData);
  }

  getZipFilesData(fullFiscalYear: string): Observable<NgResponse<ZipFilesManagementResponse>> {
    return this.get<ZipFilesManagementResponse>('getZipFilesData', `api/ZipFilesManagement/${fullFiscalYear}`);
  }

  getZipFile(fullFiscalYear: string, blobId: string): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('getZipFile', `api/ZipFilesManagement/${fullFiscalYear}/${blobId}`);
  }
}
